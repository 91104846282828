<template>
  <div>
    <div v-if="pending">Подождите ....</div>
    <div v-if="current">
      <div class="row mt-l">
        <div class="col-sm-9 col-xs-12">
          <h1 class="text-bold text-size-h1 mb-s">
            {{ current.full_name || current.short_name }}
          </h1>
        </div>
        <div class="col-sm-3 col-xs-12">
          <a
            :href="currentExternalLink"
            class="external-link link"
            target="_blank"
            rel="noopener noreferrer"
            >Кабинет организатора
            <BaseIcon
              glyph="link"
              width="12px"
          /></a>
        </div>
      </div>
      <p
        v-if="current.address"
        class="mb-s">
        {{ current.address }}
      </p>
      <div
        v-if="organizations.length > 1"
        class="row">
        <div class="col-md-5 col-sm-4 col-xs-12">
          <div class="mb-l">
            <BaseSelect
              :options="restOrganizations"
              label="title"
              :allow-empty="false"
              placeholder="Выбрать другую организацию"
              @select="handleSelect"></BaseSelect>
          </div>
        </div>
      </div>

      <!-- <section class="panel radius-m mb-l">
        <h2 class="text-size-h2 text-bold mb-s">
          Соглашение об обмене данными
        </h2>
        <p>Перейти в кабинет организации для подписания соглашения</p>
      </section> -->

      <!-- Приглашение менторов и список менторов организации -->
      <section
        v-if="mentorLink"
        class="panel radius-m mb-m">
        <h2 class="text-size-h2 text-bold mb-m">
          Пригласить наставника в организацию
        </h2>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 mb-m">
            <BaseInput
              :value="mentorLink"
              class="mb-s" />
            <p class="text-size-xs color-meta">
              Отправьте эту ссылку всем наставникам вашей организации, чтобы они
              смогли зарегистрироваться и пригласить своих учеников.
            </p>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-5 col-xs-12">
            <BaseButton
              theme="primary-border"
              @click="handleCopyMentor"
              >Копировать ссылку</BaseButton
            >
          </div>
        </div>
      </section>

      <section class="panel radius-m mb-m">
        <h2 class="text-size-h2 text-bold mb-l">Наставники организации</h2>
        <p
          v-if="!currentMentors.length"
          class="text-size-xs color-meta">
          Вы&nbsp;пока никого не&nbsp;пригласили
        </p>
        <div v-else>
          <UserCard
            v-for="mentor in currentMentors"
            :key="mentor.id"
            :avatar="mentor.avatar"
            class="mb-m"
            theme="block">
            <template #name
              >{{ mentor.first_name }} {{ mentor.last_name }}</template
            >
            <template #info>{{ mentor.email }}</template>
          </UserCard>

          <BasePagination
            v-if="mentorsAllPage > 1"
            class="mt-l"
            :current-page="mentorsPage"
            :all-page="mentorsAllPage"
            @changePage="getMentors" />
        </div>
      </section>
      <!-- end -->

      <!-- Приглашение организаций и список и список заявок на добавление -->
      <section
        v-if="organizationLink"
        class="panel radius-m mb-m">
        <h2 class="text-size-h2 text-bold mb-m">
          Пригласить дочернюю организацию
        </h2>
        <p class="mb-m">
          Если наставники из&nbsp;вашей организации уже зарегистрировались
          и&nbsp;пригласили учеников, отправьте им&nbsp;эту ссылку, чтобы они
          присоединились к&nbsp;вашей организации. Это позволит учесть
          их&nbsp;результаты при сборе общей статистики по&nbsp;вашей
          организации.
        </p>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-7 col-xs-12 mb-m">
            <BaseInput
              :value="organizationLink"
              class="mb-s" />
          </div>
          <div class="col-lg-5 col-md-6 col-sm-5 col-xs-12">
            <BaseButton
              theme="primary-border"
              @click="handleCopy(organizationLink)"
              >Копировать ссылку</BaseButton
            >
          </div>
        </div>
      </section>

      <section
        v-if="subsidiaryOrganizations.length || inviteRequests.length"
        class="panel radius-m">
        <h2 class="text-size-h2 text-bold mb-l">Дочерние организации</h2>
        <div v-if="subsidiaryOrganizations.length">
          <div
            v-for="organization in subsidiaryOrganizations"
            :key="organization.id"
            class="organization">
            <div class="organization__name">{{ organization.full_name }}</div>
            <div
              v-if="organization.address"
              class="organization__owner">
              {{ organization.address }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-size-xs color-meta">
          У вас пока нет дочерних организаций
        </div>

        <div
          v-if="inviteRequests.length"
          class="panel__insert mt-l">
          <div class="text-size-h3 text-bold mb-m">
            Запросы на&nbsp;присоединение организации
          </div>
          <div
            v-for="req in inviteRequests"
            :key="req.id"
            class="mt-l">
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-xs-12">
                <div class="text-size-s mb-m">
                  Присоединить организацию &laquo;{{
                    req.source.full_name || req.source.short_name
                  }}&raquo; (владелец: {{ req.author.first_name }}&nbsp;{{
                    req.author.last_name
                  }}) как дочернюю к&nbsp;&laquo;{{
                    current.full_name || current.short_name
                  }}&raquo;?
                </div>

                <div class="btn-row btn-row--reverse">
                  <BaseButton
                    theme="primary-border"
                    @click="handleUpdateRequest(req.id, 'r')"
                    >отклонить</BaseButton
                  >&emsp;
                  <BaseButton @click="handleUpdateRequest(req.id, 'a')"
                    >принять</BaseButton
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end -->
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/utils";
import { talentRequest } from "@/services/api";
import UserCard from "@/components/mentor/UserCard";
export default {
  name: "MentorOrganization",
  metaInfo() {
    return {
      title: "Страница организации",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  components: {
    UserCard,
  },
  data() {
    return {
      pending: false,
      currentMentors: [],
      mentorsLimit: 5,
      mentorsPage: 0,
      mentorsAllPage: 0,
      inviteRequests: [],
      subsidiaryOrganizations: [],
    };
  },
  computed: {
    organizations() {
      return this.$store.state.mentor.organizations;
    },
    current() {
      const { organizations } = this;
      const selected = +this.$route.params.id;
      if (!organizations || !organizations.length) return;
      if (!selected) return organizations[0];
      return organizations.find((n) => n.id === selected);
    },
    restOrganizations() {
      const { organizations, current } = this;
      return organizations.reduce((acc, org) => {
        if (org.id !== current?.id) {
          acc.push({
            title: org.full_name || org.short_name,
            id: org.id,
          });
        }
        return acc;
      }, []);
    },
    mentorLink() {
      const { current } = this;
      if (!current) return;
      return this.$store.state.mentor.mentorInvites[current.id];
    },
    organizationLink() {
      const { current } = this;
      if (!current) return;
      return this.$store.state.mentor.organizationInvites[current.id];
    },
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    currentExternalLink() {
      const { current } = this;
      if (!current) return;
      return `${process.env.VUE_APP_TALENT_BASE_URL}/org/organizations/${current.id}/statistics`;
    },
  },
  watch: {
    "$route.params.id": {
      handler(val) {
        if (val) {
          this.init();
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async getMentorInviteLink() {
      const { current } = this;
      if (!current) return;
      try {
        await this.$store.dispatch("mentor/getMentorInviteLink", current.id);
      } catch (error) {
        console.log("error", error);
      }
    },
    async getOrganizationInviteLink() {
      const { current } = this;
      if (!current) return;
      try {
        await this.$store.dispatch(
          "mentor/getOrganizationInviteLink",
          current.id
        );
      } catch (error) {
        console.log("error", error);
      }
    },
    handleSelect(val) {
      this.$router.push({
        params: {
          id: val.id,
        },
      });
    },
    handleCopyMentor() {
      this.handleCopy(this.mentorLink);
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "mentor_created_invite_link_for_mentors",
      });
    },
    handleCopy(link) {
      copyToClipboard(link);
    },
    async init() {
      this.pending = true;
      const { id } = this.$route.params;
      await this.$store.dispatch("mentor/getMyOrganizations");
      if (this.organizations.length && !id) {
        this.$router.push({
          name: "mentor-organization",
          params: { id: this.organizations[0].id },
        });
      }
      if (id) {
        this.$store
          .dispatch("mentor/getMentorInviteLink", id)
          .catch((error) => {
            console.log("error.message", error.message);
          });
        this.$store
          .dispatch("mentor/getOrganizationInviteLink", id)
          .catch((error) => {
            console.log("error.message", error.message);
          });
        this.getInviteRequests();
        this.getSubsidiaryOrganizations();
        this.getMentors(0);
        this.getMentorInviteLink();
        this.getOrganizationInviteLink();
      }
      this.pending = false;
    },
    async getMentors(page = 0) {
      const { id } = this.$route.params;
      const { mentorsLimit } = this;
      if (id) {
        try {
          const { data } = await talentRequest({
            method: "GET",
            url: `/api/users/${this.talentUser.id}/organizations/${id}/admins/`,
            params: {
              limit: mentorsLimit,
              offset: mentorsLimit * page,
            },
          });
          this.currentMentors = data.results.map((item) => item.user);
          this.mentorsAllPage = Math.ceil(data.count / mentorsLimit);
          this.mentorsPage = page + 1;
        } catch (error) {
          console.log(error);
        }
      }
    },
    // Запросы на присоединение организации
    async getInviteRequests() {
      const { current } = this;
      if (!current) return;
      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `/api/users/${this.talentUser.id}/organizations/${current.id}/invite/requests/`,
          params: {
            limit: 50,
            offset: 0,
            // status: "",
            target: current.id,
          },
        });
        // Сделали пока так, бэк не распознает пустую строку
        this.inviteRequests = data.results.filter((item) => !item.status);

        if (this.inviteRequests.length) {
          const { data: users } = await talentRequest({
            method: "GET",
            url: `/api/users/`,
            params: {
              ids: this.inviteRequests.map((item) => item.author).join(","),
            },
          });

          this.inviteRequests.forEach((org) => {
            org.author = users.results.find((user) => user.id === org.author);
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    // Дочерние организации
    async getSubsidiaryOrganizations() {
      const { current } = this;
      if (!current || !current.subsidiary_organizations.length) return;

      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `/api/organizations/`,
          params: {
            ids: current.subsidiary_organizations.join(","),
          },
        });
        this.subsidiaryOrganizations = data.results;
      } catch (error) {
        console.log("error", error);
      }
    },
    // Обработать запрос
    async handleUpdateRequest(id, status) {
      const { current } = this;
      try {
        await talentRequest({
          method: "PUT",
          url: `/api/users/${this.talentUser.id}/organizations/${current.id}/invite/requests/${id}/`,
          data: {
            status,
          },
        });
        let org = {};
        this.inviteRequests = this.inviteRequests.reduce((acc, val) => {
          if (val.id === id) {
            org = val;
          } else {
            acc.push(val);
          }
          return acc;
        }, []);
        if (status === "a") {
          this.subsidiaryOrganizations.push(org.source);
        }
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось изменить запрос",
          message: error.message,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.external-link {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  float: right;
  margin-bottom: 5px;

  .base-icon {
    margin-left: 10px;
  }

  .media-max-xs({
    float: left;
  });
}
.organization {
  & + & {
    margin-top: 25px;
  }

  &__name {
    text-transform: uppercase;
  }

  &__owner {
    font-size: 12px;
    color: @grey-blue;
  }
}
</style>
